@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Inter:wght@500&display=swap');

.upgrade-section {
  display: flex;
  padding: 4rem 0;
  color: white;
  min-height: 100vh; 
  position: relative;

}
.blur-bg {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(135%, -5%); /* Adjust these percentages as needed */
}
.blur-bg-2 {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-75%, 85%); /* Adjust these percentages as needed */
  z-index: 2;
}
/* .ring-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 700%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transform: translate(-25%, 85%);
  z-index: 3;
} */

.upgrade-content {
  flex: 1;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  /* padding: 2rem; */
  position: relative;

}

.grid-bg {
  position: absolute;
  top: 0;
  left: -450px; /* Adjust this value to move the image left or right */
  height: 100%;
  z-index: 1;
  top: 100px;
}

.content-wrapper {
  position: relative;
  z-index: 2;
}

.upgrade-text h2, .stat-item h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 64px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 1rem;
  display: flex;
  /* align-items: center; */
}

.update-icon {
  margin-right: 1rem;
  /* font-size: 1.5em; */
  width: 57px;
  height: 57px;
  margin-top: 0.5rem;
}

.upgrade-text p, .stat-item p {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: rgba(136, 147, 187, 1);
}

.upgrade-stats {
  margin-top: 2rem;
}

.upgrade-image {
  /* flex: 1; */
  display: flex;
  justify-content: right;
  align-items: right;
  width: auto;
}

.upgrade-image img {
  /* max-width: 100%; */
  height: auto;
}
@media (max-width: 1200px) {
  .upgrade-image  {
    width: 400px;
    height: 100%;

  }
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .upgrade-section {
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .upgrade-content {
    padding-right: 0;
    margin-bottom: 2rem;
  }

  .upgrade-image {
    order: -1;
    margin-bottom: 2rem;
  }

  .upgrade-image img {
    width: 100%;
    max-width: 333px;
    margin: 0 auto;
    display: block;
  }

  .icon-text-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .update-icon {
    margin-bottom: 1rem;
  }

  .upgrade-text h2 {
    font-size: 32px;
    line-height: 1.2;
  }

  .upgrade-text p {
    font-size: 16px;
    line-height: 1.5;
  }

  .content-wrapper {
    padding: 0 1rem;
  }

  .grid-bg, .blur-bg, .blur-bg-2 {
    display: none;
  }
}