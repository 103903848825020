.seo-journey {
    padding: 120px 0;
    background: linear-gradient(180deg, #0A0A0A 0%, #151515 100%);
    position: relative;
    overflow: hidden;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  
  .seo-journey-content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
    position: relative;
    z-index: 2;
  }
  
  .seo-journey-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 56px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 100px;
    letter-spacing: -0.03em;
    position: relative;
  }
  
  .seo-journey-timeline {
    position: relative;
    padding: 40px 0;
  }
  
  /* Timeline line */
  .timeline-line {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg,
      transparent,
      rgba(232, 155, 93, 0.3),
      rgba(232, 155, 93, 0.3),
      transparent
    );
    transform: translateY(-50%);
  }
  
  /* Timeline steps */
  .timeline-steps {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 20px;
  }
  
  .timeline-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    position: relative;
    transition: all 0.3s ease;
  }
  
  .step-icon {
    width: 64px;
    height: 64px;
    border-radius: 20px;
    background: linear-gradient(135deg, rgba(232, 155, 93, 0.1), rgba(232, 155, 93, 0.05));
    border: 1px solid rgba(232, 155, 93, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E89B5D;
    margin-bottom: 24px;
    position: relative;
    z-index: 2;
    transition: all 0.3s ease;
  }
  
  .step-icon svg {
    transition: all 0.3s ease;
  }
  
  .step-content {
    text-align: center;
    padding: 0 10px;
    transition: all 0.3s ease;
  }
  
  .step-number {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #E89B5D;
    margin-bottom: 12px;
    display: block;
    opacity: 0.8;
  }
  
  .step-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.4;
    transition: all 0.3s ease;
  }
  
  /* Step hover effects */
  .timeline-step:hover .step-icon {
    transform: translateY(-5px);
    background: linear-gradient(135deg, rgba(232, 155, 93, 0.15), rgba(232, 155, 93, 0.1));
    border-color: rgba(232, 155, 93, 0.4);
    box-shadow: 0 20px 40px rgba(232, 155, 93, 0.1);
  }
  
  .timeline-step:hover .step-icon svg {
    transform: scale(1.1);
  }
  
  .timeline-step:hover .step-title {
    color: #fff;
  }
  
  /* Background Elements */
  .journey-glow-1 {
    position: absolute;
    top: -200px;
    right: -200px;
    width: 600px;
    height: 600px;
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.1) 0%, transparent 70%);
    filter: blur(60px);
  }
  
  .journey-glow-2 {
    position: absolute;
    bottom: -200px;
    left: -200px;
    width: 600px;
    height: 600px;
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.08) 0%, transparent 70%);
    filter: blur(60px);
  }
  
  .journey-grid {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
    background-size: 40px 40px;
    opacity: 0.1;
  }
  
  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .timeline-step {
    animation: fadeInUp 0.6s ease forwards;
    opacity: 0;
  }
  
  .timeline-step:nth-child(1) { animation-delay: 0.1s; }
  .timeline-step:nth-child(2) { animation-delay: 0.2s; }
  .timeline-step:nth-child(3) { animation-delay: 0.3s; }
  .timeline-step:nth-child(4) { animation-delay: 0.4s; }
  .timeline-step:nth-child(5) { animation-delay: 0.5s; }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .seo-journey-content {
      padding: 0 40px;
    }
  }
  
  @media (max-width: 768px) {
    .seo-journey {
      padding: 60px 0;
    }
  
    .seo-journey-content {
      padding: 0 20px;
    }
  
    .seo-journey-title {
      font-size: 36px;
      margin-bottom: 60px;
    }
  
    .timeline-steps {
      flex-direction: column;
      gap: 40px;
    }
  
    .timeline-line {
      width: 1px;
      height: 100%;
      left: 32px;
      top: 0;
      transform: none;
    }
  
    .timeline-step {
      flex-direction: row;
      align-items: center;
      text-align: left;
      gap: 24px;
    }
  
    .step-icon {
      margin-bottom: 0;
    }
  
    .step-content {
      text-align: left;
    }
  }