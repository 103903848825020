.reg-form label {
    display: block;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    color: #4958E2;
}

.reg-form input {
    width: 100%;
    height: 61px;
    
    background: #0D0E21;
    border: 1px solid rgba(60, 70, 172, 0.52);
    box-sizing: border-box;
    border-radius: 19px;
    padding: 0 30px;
    flex: 1;
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    margin-top: 8px;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
}

.reg-form input:focus {
    border-color: #0AA6FE;
    box-shadow: 0px 0px 13px rgb(32 118 244 / 60%);
}

  
/* .reg-form button:disabled {
    background-color: yellow;
}

.reg-form button {
    background: linear-gradient(180deg, #0AA6FE -46.51%, #510DE0 138.37%);
    transition: box-shadow 0.5s;
    box-shadow: 0 0 0 #2076f4;
    display: inline-flex;
    align-items: center;
    height: 61px;
    border-radius: 19px;
    padding: 0 25px;
    font-weight: 500;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    border: 0 none;
    line-height: normal;
    -webkit-appearance: none;
    overflow: visible;
} */


#somebtn:disabled{
    opacity: 0.6;
    background-color: rgb(255, 0, 0) !important;
}

#phone-country-dropdown{
    width: 100%;
    height: 61px;
    background: #0D0E21;
    border: 1px solid rgba(60, 70, 172, 0.52);
    box-sizing: border-box;
    border-radius: 19px;
    padding: 0 30px;
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    margin-top: 8px;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
}

#form-error-msg{
    color: red;
}

#form-phone-component{
    display: flex;
}

#form-message{
    margin: 0;
    padding: 0;
    width: 100%;
    /* color: #0AA6FE; */
    color: white;
    margin-left: 10px;
    padding-left: 30px;
    position: relative;
    margin-top: 5px;
    

}
#form-message svg{
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    color: #084365;

}


.form-top-label {
    display: flex;
    flex-direction: row;
}
.form-top-label-text{
    flex: 1;
}
.form-top-label-notice{
    flex: 1;
}

.form-top-label label {
   flex: 1;
   width: 50%;
}
.form-top-label p {
    flex: 1;
    width: 50%;


 }
 
#top-form-message{ 
    margin: 0;
    padding: 0;
    width: 100%;
    /* color: #0AA6FE; */
    color: white;
    /* padding-left: 30px; */
    position: relative;
    padding-left: 55%;


}

#top-form-message svg{
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 40%;
    color: #084365;
}

#form-message-error {
    margin: 0;
    padding: 0;
    color: rgb(255, 255, 255);
    text-align: left;
    margin-top: 2%;
    padding: 4px;
    position: relative;
    padding-left: 40%;

}

#form-message-error svg {
    color: red;
    position: absolute;
    top: 0;
    left: 33%;
    margin-top: 1%;

}

