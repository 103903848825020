.seo-limitations {
    padding: 120px 0;
    background: linear-gradient(180deg, #0A0A0A 0%, #151515 100%);
    position: relative;
    overflow: hidden;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  
  .seo-limitations-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
    z-index: 2;
  }
  
  .seo-limitations-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 56px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 20px;
    letter-spacing: -0.03em;
  }
  
  .seo-limitations-subtitle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    margin-bottom: 80px;
  }
  
  .seo-limitations-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 60px;
  }
  
  .limitation-card {
    position: relative;
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    padding: 40px;
    transition: all 0.3s ease;
  }
  
  .limitation-card:hover {
    transform: translateY(-5px);
    border-color: rgba(232, 155, 93, 0.3);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  }
  
  .limitation-icon-wrapper {
    width: 80px;
    height: 80px;
    background: linear-gradient(135deg, rgba(232, 155, 93, 0.15) 0%, rgba(209, 126, 59, 0.15) 100%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .limitation-icon {
    color: #E89B5D;
  }
  
  .limitation-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 20px;
    letter-spacing: -0.02em;
    line-height: 1.3;
  }
  
  .limitation-description {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .seo-limitations-footer {
    max-width: 900px;
    margin: 0 auto;
    padding: 40px;
    background: linear-gradient(145deg, rgba(232, 155, 93, 0.1), rgba(232, 155, 93, 0.05));
    border: 1px solid rgba(232, 155, 93, 0.2);
    border-radius: 24px;
    text-align: center;
  }
  
  .seo-limitations-footer p {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
  }
  
  /* Background Elements */
  .limitations-glow-1 {
    position: absolute;
    top: -200px;
    right: -200px;
    width: 600px;
    height: 600px;
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.1) 0%, transparent 70%);
    filter: blur(60px);
  }
  
  .limitations-glow-2 {
    position: absolute;
    bottom: -200px;
    left: -200px;
    width: 600px;
    height: 600px;
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.08) 0%, transparent 70%);
    filter: blur(60px);
  }
  
  .limitations-grid-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
    background-size: 40px 40px;
    opacity: 0.1;
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .seo-limitations-grid {
      gap: 20px;
    }
  }
  
  @media (max-width: 992px) {
    .seo-limitations-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .seo-limitations {
      padding: 60px 0;
    }
  
    .seo-limitations-content {
      padding: 0 20px;
    }
  
    .seo-limitations-title {
      font-size: 36px;
    }
  
    .seo-limitations-grid {
      grid-template-columns: 1fr;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  
    .limitation-card {
      padding: 30px;
    }
  }