@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Inter:wght@500&display=swap');

.dashboard-container {
  padding: 20px;
  color: white;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.grid-bg-an{
  position: absolute;
  width: 100%;
  height: auto;
  max-width: none;
  z-index: -1;
  pointer-events: none;
}

.chart-image {
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  margin-top: 175px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  color: #FFFFFF;
  margin-top: 20px;
}

.description {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: #8893BB;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Responsive styles */
.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .mobile-image {
    width: 100%;
    max-width: 328px;
    margin-bottom: 20px;
  }
}