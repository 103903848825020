@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Montserrat:wght@700&display=swap');



.healm-consultation-container {
  color: white;
  padding: 40px 20px;
  max-width: 900px;
  margin: 0 auto;
}

.healm-consultation-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 77px;
  margin-top: 100px;
}
.form-bg{
  position: absolute;
  /* top: 0;
  right: 0; */
  width: 1200px; /* Adjust as needed */
  height: auto;
  max-width: none;
  z-index: -1;
  pointer-events: none;
  /* margin-top: 133px; */
  margin-left: -200px;
  
}

.healm-consultation-form {
  background: rgba(34, 39, 58, 0.5);
  border: 1.5px solid;
  border-image-source: linear-gradient(180deg, #22273A 0%, rgba(14, 19, 38, 0) 100%);
  padding: 30px 30px 90px;
  border-radius: 52px;
  position: relative;
}

.healm-consultation-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 20px;
}

.healm-form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.healm-form-input,
.healm-form-textarea {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 1);
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  transition: all 0.3s ease;
}

.healm-form-input[type="text"],
.healm-form-input[type="email"],
.healm-form-input[type="tel"] {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 1);
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  transition: all 0.3s ease;
}

.healm-form-input::placeholder,
.healm-form-textarea::placeholder {
  color: rgba(136, 147, 187, 0.8);
}

.healm-form-input:focus,
.healm-form-textarea:focus {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  outline: none;
}

.healm-form-textarea {
  height: 120px;
  resize: vertical;
  margin-bottom: 20px;
}

.healm-submit-btn {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  padding: 0;
  background: linear-gradient(92.81deg, #7C42F6 6.58%, #42AAF6 100%);
  /* border: 8px solid transparent; */
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  box-shadow: 0px -27px 24px 0px rgba(0, 0, 0, 0.5) inset;
}

.healm-submit-btn::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  background: linear-gradient(92.01deg, rgba(124, 66, 246, 0.24) 0%, rgba(70, 165, 246, 0.24) 103.92%);
  border-radius: 50%;
  z-index: -1;
}

.healm-submit-icon {
  color: white;
  font-size: 38px;
}

.healm-submit-btn:hover {
  transform: translateX(-50%) scale(1.05);
}

.healm-submit-btn:active {
  transform: translateX(-50%) scale(0.95);
}

@media (max-width: 950px) {
  .healm-consultation-container {
    padding: 20px;
  }

  .healm-form-row {
    flex-direction: column;
    gap: 10px;
  }

  .healm-consultation-title {
    font-size: 36px;
    line-height: 48px;
  }

  .healm-consultation-subtitle {
    font-size: 24px;
    line-height: 32px;
  }
}