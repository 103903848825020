@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Inter:wght@600&display=swap');

.how-it-works {
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  color: #FFFFFF;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.process-container {
  width: 100%;
  position: relative;
  z-index: 2;
}

.button-overlay {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  margin-top: 20px;
  width: auto;
}

.how-bg {
  position: absolute;
  width: 100vw;
  min-width: 1200px; /* Adjust this value based on the original image width */
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  pointer-events: none;
  object-fit: cover;
}

.desktop-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 33px auto 0;
}

.mobile-image, .mobile-blimp {
  display: none;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  h2 {
    font-size: 36px;
    line-height: 48px;
  }
  
  .button-overlay {
    top: 10px;
    width: 100%;
    max-width: 300px; /* Adjust this value as needed */
    left: 50%;
    transform: translateX(-50%);
  }

  .button-overlay button {
    width: 100%;
  }

  .desktop-image {
    display: none;
  }

  .mobile-image, .mobile-blimp {
    display: block;
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 0 auto;
  }

  .mobile-image {
    margin-top: 120px;
  }

  .mobile-blimp {

  }
}