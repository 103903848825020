@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

.signup-special {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #1C1C38;
  color: #fff;
  font-family: 'Poppins', sans-serif; /* Apply Poppins globally */
  height: 100vh;

}

.signup-special-p{
  font-size: 42px;
  font-weight: 700;
  padding: 10% 0 10% 0;

}
.signup-special-content {
  padding: 7%;

  width: 100%;

}

.signup-special-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;

}

.signup-special-content p {
  font-size: 16px;
  margin-bottom: 20px;
}

.signup-special-content form {
  display: flex;
  flex-direction: column;
}

.signup-special-content input {
  padding: 10px;
  margin-bottom: 33px;
  border: none;
  border-radius: 4px;
  background-color: #1C1C38;
  border: 1px solid white;
  color: #ffffff65;
  height: 59px;
  font-size: 18px;
  padding-left: 20px;
  padding-top: 12px;
  transition: border-color 0.3s ease;
}

.signup-special-content input:focus {
  outline: none;
  border-color: #007bff;
}

.signup-special-content button {
  padding: 10px;
  border: none;
  border-radius: 100px;
  background-color: #007bff;
  color: #fff;
  font-size: 20px;
  font-weight: lighter;
  cursor: pointer;
  height: 59px;
  transition: background-color 0.3s ease;
}

.signup-special-content button:hover {
  background-color: #0056b3;
}

.signup-special-input-group {
  position: relative;
  width: auto;
}

.signup-special-input-group input {
  width: 100%;
}

.signup-special-input-group label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 10px;
  margin-top: -27px;
  transition: all 0.3s;
  background-color: #1C1C38;
  color: #ffffff;
  pointer-events: none;
  font-size: 18px;
}

.signup-special-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the height to 100% viewport height */

  background-image: url('./signup-landingpage-bg.png'); /* Set your image path here */
  width: 100%;
  /* padding: 7%; */
  background-size: cover; /* Ensure the background covers the div */
  position: relative; /* Required for the overlay positioning */
  margin: 0; /* Removes any default margin */

}
.signup-special-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2); /* Black overlay with 10% opacity */
  pointer-events: none; /* Ensures the overlay does not interfere with user interaction */
  
}
.signup-special-image img {
  width: 70%;
  max-width: 700px;
  height: auto;
  object-fit: contain;
  padding: 0;
}

.signup-special-image h2 {
  font-size: 82px !important;
  color: white;
  text-align: center;
  margin-bottom: 20px;
  padding: 7%;
  font-weight: 700;

}

/* Media Queries */
@media screen and (min-width: 1000px) {
  .signup-special {
    flex-direction: row;
  }

  .signup-special-content {
    width: 50%;
    padding-right: 40px;
  }

  .signup-special-image {
    height: 100vh;
    width: 50%;
    padding: 0;
  }

  .signup-special-image h2 {
    font-size: 48px;
  }

}

@media screen and (min-width: 1024px) {
  .signup-special-content h2 {
    font-size: 32px;
  }

  .signup-special-content p {
    font-size: 18px;
  }

  .signup-special-image h2 {
    font-size: 64px;
  }

}
@media screen and (min-width: 1000px) {
  .signup-special {
    flex-direction: row; /* Ensure the layout is horizontal */
    justify-content: space-between; /* Ensures spacing is maintained */
  }

  .signup-special-content {
    flex: 1; /* Allows the element to grow */
    min-width: 599px; /* Set a minimum width for the content */
    max-width: 40%; /* Optionally, you can cap the width to maintain proportions */
  }

  .signup-special-image {
    flex: 2; /* Allows the element to take twice the space of the content section */
    padding: 0; /* Optional padding adjustment */
  }
}
