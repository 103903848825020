.seo-benefits {
    padding: 120px 0;
    background: linear-gradient(180deg, #0A0A0A 0%, #151515 100%);
    position: relative;
    overflow: hidden;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  
  .seo-benefits-content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
    position: relative;
    z-index: 2;
  }
  
  .seo-benefits-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 56px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 80px;
    letter-spacing: -0.03em;
    position: relative;
    display: inline-block;
  }
  
  .seo-benefits-title::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100px;
    height: 4px;
    background: linear-gradient(90deg, #E89B5D, transparent);
  }
  
  .seo-benefits-grid {
    display: flex;
    gap: 30px;
    margin-bottom: 60px;
    overflow-x: auto;
    padding: 20px 0;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .seo-benefits-grid::-webkit-scrollbar {
    display: none;
  }
  
  .seo-benefit-card {
    min-width: 400px;
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    padding: 40px;
    transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    scroll-snap-align: start;
    position: relative;
  }
  
  .seo-benefit-card.active {
    background: linear-gradient(145deg, rgba(232, 155, 93, 0.1), rgba(232, 155, 93, 0.05));
    border-color: rgba(232, 155, 93, 0.3);
    transform: translateY(-10px);
  }
  
  .seo-benefit-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .seo-benefit-icon {
    width: 60px;
    height: 60px;
    border-radius: 16px;
    background: linear-gradient(135deg, rgba(232, 155, 93, 0.15) 0%, rgba(209, 126, 59, 0.15) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
  
  .seo-benefit-icon-inner {
    color: #E89B5D;
    transition: all 0.3s ease;
  }
  
  .seo-benefit-number {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 120px;
    font-weight: 700;
    color: rgba(232, 155, 93, 0.1);
    line-height: 1;
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 0.3s ease;
  }
  
  .active .seo-benefit-number {
    color: rgba(232, 155, 93, 0.15);
    transform: scale(1.1);
  }
  
  .seo-benefit-content {
    position: relative;
    z-index: 1;
  }
  
  .seo-benefit-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 28px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 16px;
    letter-spacing: -0.02em;
    line-height: 1.3;
  }
  
  .seo-benefit-description {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .benefits-progress {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: 40px;
  }
  
  .progress-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .progress-dot.active {
    background: #E89B5D;
    transform: scale(1.2);
  }
  
  /* Hover Effects */
  .seo-benefit-card:hover {
    transform: translateY(-10px);
    border-color: rgba(232, 155, 93, 0.3);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  }
  
  .seo-benefit-card:hover .seo-benefit-icon-inner {
    transform: scale(1.1) rotate(10deg);
  }
  
  /* Background Elements */
  .benefits-glow-1 {
    position: absolute;
    top: -200px;
    right: -200px;
    width: 600px;
    height: 600px;
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.1) 0%, transparent 70%);
    filter: blur(60px);
    pointer-events: none;
  }
  
  .benefits-glow-2 {
    position: absolute;
    bottom: -200px;
    left: -200px;
    width: 600px;
    height: 600px;
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.08) 0%, transparent 70%);
    filter: blur(60px);
    pointer-events: none;
  }
  
  .benefits-grid-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
    background-size: 40px 40px;
    opacity: 0.1;
  }
  
  /* Animations */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .seo-benefit-card {
    animation: slideIn 0.6s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    opacity: 0;
  }
  
  /* Responsive Design */
  @media (max-width: 1440px) {
    .seo-benefits-content {
      padding: 0 40px;
    }
  }
  
  @media (max-width: 768px) {
    .seo-benefits {
      padding: 60px 0;
    }
  
    .seo-benefits-content {
      padding: 0 20px;
    }
  
    .seo-benefits-title {
      font-size: 36px;
      margin-bottom: 40px;
      text-align: center;
    }
  
    .seo-benefit-card {
      min-width: 300px;
      padding: 30px;
    }
  
    .seo-benefit-number {
      font-size: 80px;
      top: 10px;
      right: 20px;
    }
  
    .seo-benefit-title {
      font-size: 24px;
    }
  }