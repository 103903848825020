.seo-footer {
    background: linear-gradient(180deg, #0A0A0A 0%, #151515 100%);
    padding: 80px 0 40px;
    position: relative;
    overflow: hidden;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  
  .seo-footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
    z-index: 2;
  }
  
  .seo-footer-main {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 60px;
    margin-bottom: 60px;
  }
  
  .seo-footer-brand {
    max-width: 600px;
  }
  
  .seo-footer-logos {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .seo-footer-logo {
    height: 40px;
    width: auto;
  }
  
  .seo-footer-partner {
    margin-left: 53px;
    padding-left: 43px;
    border-left: 1px solid rgba(232, 155, 93, 0.3);
  }
  
  .seo-footer-partner img {
    height: 33px;
  }
  
  .seo-footer-description {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .seo-footer-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
  
  .seo-footer-column h4 {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 24px;
  }
  
  .seo-footer-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .seo-footer-column ul li {
    margin-bottom: 16px;
  }
  
  .seo-footer-column ul li a {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .seo-footer-column ul li a:hover {
    color: #E89B5D;
  }
  
  .seo-footer-social {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 40px 0;
  }
  
  .seo-social-icons {
    display: flex;
    justify-content: center;
    gap: 24px;
  }
  
  .seo-social-icons a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: linear-gradient(135deg, rgba(232, 155, 93, 0.15) 0%, rgba(209, 126, 59, 0.15) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E89B5D;
    font-size: 18px;
    transition: all 0.3s ease;
  }
  
  .seo-social-icons a:hover {
    transform: translateY(-2px);
    background: linear-gradient(135deg, rgba(232, 155, 93, 0.3) 0%, rgba(209, 126, 59, 0.3) 100%);
  }
  
  .seo-footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .seo-footer-policies {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .seo-footer-policies a {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .seo-footer-policies a:hover {
    color: #E89B5D;
  }
  
  .separator {
    color: rgba(255, 255, 255, 0.3);
  }
  
  .seo-footer-copyright {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }
  
  /* Background Elements */
  .footer-glow-1 {
    position: absolute;
    top: 0;
    right: -200px;
    width: 600px;
    height: 600px;
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.1) 0%, transparent 70%);
    filter: blur(60px);
  }
  
  .footer-glow-2 {
    position: absolute;
    bottom: -300px;
    left: -200px;
    width: 600px;
    height: 600px;
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.08) 0%, transparent 70%);
    filter: blur(60px);
  }
  
  .footer-grid {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
    background-size: 40px 40px;
    opacity: 0.1;
  }
  
  /* Responsive Design */
  @media (max-width: 992px) {
    .seo-footer-main {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  
    .seo-footer-brand {
      max-width: none;
    }
  }
  
  @media (max-width: 768px) {
    .seo-footer {
      padding: 60px 0 30px;
    }
  
    .seo-footer-content {
      padding: 0 20px;
    }
  
    .seo-footer-links {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  
    .seo-footer-bottom {
      flex-direction: column;
      gap: 20px;
      text-align: center;
    }
  }
  
  @media (max-width: 480px) {
    .seo-footer-logos {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  
    .seo-footer-partner {
      margin-left: 0;
      padding-left: 0;
      border-left: none;
    }
  }


  .seo-footer {
    background: linear-gradient(180deg, #0A0A0A 0%, #151515 100%);
    padding: 80px 0 40px;
    position: relative;
    overflow: hidden;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.seo-footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
    z-index: 2;
}

/* Main grid layout */
.seo-footer-main {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 60px;
    margin-bottom: 60px;
}

/* Brand section */
.seo-footer-brand {
    max-width: 600px;
}

.seo-footer-logos {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.seo-footer-logo {
    height: 40px;
    width: auto;
}

.seo-footer-partner {
    margin-left: 53px;
    padding-left: 43px;
    border-left: 1px solid rgba(232, 155, 93, 0.3);
}

.seo-footer-partner img {
    height: 33px;
}

.seo-footer-description {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
}

/* Links section */
.seo-footer-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

.seo-footer-column h4 {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 24px;
}

.seo-footer-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.seo-footer-column ul li {
    margin-bottom: 16px;
}

.seo-footer-column ul li a {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    transition: color 0.3s ease;
    display: inline-block;
    padding: 4px 0;
}

.seo-footer-column ul li a:hover {
    color: #E89B5D;
}

/* Social section */
.seo-footer-social {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 40px 0;
}

.seo-social-icons {
    display: flex;
    justify-content: center;
    gap: 24px;
}

.seo-social-icons a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: linear-gradient(135deg, rgba(232, 155, 93, 0.15) 0%, rgba(209, 126, 59, 0.15) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E89B5D;
    font-size: 18px;
    transition: all 0.3s ease;
}

.seo-social-icons a:hover {
    transform: translateY(-2px);
    background: linear-gradient(135deg, rgba(232, 155, 93, 0.3) 0%, rgba(209, 126, 59, 0.3) 100%);
}

/* Bottom section */
.seo-footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.seo-footer-policies {
    display: flex;
    align-items: center;
    gap: 16px;
}

.seo-footer-policies a {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
    transition: color 0.3s ease;
}

.seo-footer-policies a:hover {
    color: #E89B5D;
}

.separator {
    color: rgba(255, 255, 255, 0.3);
}

.seo-footer-copyright {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
}

/* Background Elements */
.footer-glow-1,
.footer-glow-2 {
    position: absolute;
    width: 600px;
    height: 600px;
    filter: blur(60px);
}

.footer-glow-1 {
    top: 0;
    right: -200px;
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.1) 0%, transparent 70%);
}

.footer-glow-2 {
    bottom: -300px;
    left: -200px;
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.08) 0%, transparent 70%);
}

.footer-grid {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
        linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
        linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
    background-size: 40px 40px;
    opacity: 0.1;
}

/* Enhanced Responsive Design */
@media (max-width: 1024px) {
    .seo-footer-content {
        padding: 0 32px;
    }

    .seo-footer-main {
        gap: 40px;
    }
}

@media (max-width: 992px) {
    .seo-footer {
        padding: 60px 0 32px;
    }

    .seo-footer-main {
        grid-template-columns: 1fr;
        gap: 48px;
    }

    .seo-footer-brand {
        max-width: none;
    }

    .seo-footer-description {
        max-width: 600px;
    }
}

@media (max-width: 768px) {
    .seo-footer {
        padding: 48px 0 32px;
    }

    .seo-footer-content {
        padding: 0 24px;
    }

    .seo-footer-main {
        gap: 40px;
        margin-bottom: 40px;
    }

    .seo-footer-links {
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;
    }

    .seo-footer-column h4 {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .seo-footer-column ul li {
        margin-bottom: 12px;
    }

    .seo-footer-social {
        padding: 32px 0;
    }

    .seo-social-icons {
        gap: 20px;
    }

    .seo-footer-bottom {
        flex-direction: column;
        gap: 24px;
        text-align: center;
        padding-top: 24px;
    }

    .seo-footer-policies {
        justify-content: center;
    }
}

@media (max-width: 576px) {
    .seo-footer {
        padding: 40px 0 24px;
    }

    .seo-footer-content {
        padding: 0 20px;
    }

    .seo-footer-logos {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }

    .seo-footer-partner {
        margin-left: 0;
        padding-left: 0;
        border-left: none;
        padding-top: 24px;
        border-top: 1px solid rgba(232, 155, 93, 0.3);
    }

    .seo-footer-links {
        grid-template-columns: 1fr;
        gap: 32px;
    }

    .seo-footer-column ul li a {
        padding: 8px 0;
    }

    .seo-social-icons a {
        width: 44px;
        height: 44px;
        font-size: 20px;
    }

    .seo-footer-policies {
        flex-direction: column;
        gap: 12px;
    }

    .separator {
        display: none;
    }
}

@media (max-width: 360px) {
    .seo-footer-content {
        padding: 0 16px;
    }

    .seo-footer-logo {
        height: 32px;
    }

    .seo-footer-partner img {
        height: 28px;
    }

    .seo-footer-description {
        font-size: 14px;
    }

    .seo-social-icons {
        gap: 16px;
    }
}