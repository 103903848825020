.healm-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 24px;
    animation: fadeIn 0.3s ease;
  }
  
  .healm-contact-modal {
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.01));
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    width: 100%;
    max-width: 560px;
    position: relative;
    animation: slideUp 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }
  
  .healm-modal-close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .healm-modal-close:hover {
    background: rgba(232, 155, 93, 0.1);
    border-color: rgba(232, 155, 93, 0.2);
    color: #E89B5D;
    transform: translateY(-2px);
  }
  
  .healm-modal-header {
    padding: 40px 40px 0;
    text-align: center;
  }
  
  .healm-modal-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 32px;
    font-weight: 600;
    color: white;
    margin-bottom: 16px;
  }
  
  .healm-phone-button {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 auto;
    padding: 12px 24px;
    background: none;
    border: 1px solid rgba(232, 155, 93, 0.3);
    border-radius: 50px;
    color: #E89B5D;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .healm-phone-button:hover {
    background: rgba(232, 155, 93, 0.1);
    transform: translateY(-2px);
  }
  
  .healm-contact-form {
    padding: 40px;
  }
  
  .form-group {
    margin-bottom: 24px;
  }
  
  .form-group label {
    display: block;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 8px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 12px 16px;
    color: white;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    transition: all 0.2s ease;
  }
  
  .form-group input:focus,
  .form-group select:focus {
    outline: none;
    border-color: rgba(232, 155, 93, 0.5);
    background: rgba(232, 155, 93, 0.05);
  }
  
  .form-group input::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  
  .form-group select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23E89B5D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
    padding-right: 40px;
  }
  
  .form-group select option {
    background: #1E1E1E;
    color: white;
  }
  
  .submit-button {
    width: 100%;
    background: linear-gradient(92.81deg, #E89B5D 6.58%, #D17E3B 100%);
    border: none;
    padding: 16px;
    border-radius: 12px;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 16px;
  }
  
  .submit-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(232, 155, 93, 0.4);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 640px) {
    .healm-contact-modal {
      border-radius: 20px;
    }
  
    .healm-modal-header {
      padding: 32px 24px 0;
    }
  
    .healm-modal-title {
      font-size: 24px;
    }
  
    .healm-contact-form {
      padding: 24px;
    }
  
    .healm-phone-button {
      font-size: 14px;
      padding: 10px 20px;
    }
  }

  /* Add these to your existing CSS */

.success-message {
    padding: 40px;
    text-align: center;
    color: white;
  }
  
  .success-message h3 {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #E89B5D;
    margin-bottom: 12px;
  }
  
  .success-message p {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .error-message {
    background: rgba(220, 38, 38, 0.1);
    border: 1px solid rgba(220, 38, 38, 0.2);
    color: rgb(248, 113, 113);
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 16px;
    font-size: 14px;
    text-align: center;
  }
  
  .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .spinner {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none !important;
  }