@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Inter:wght@600&family=Libre+Baskerville:ital@0;1&display=swap');

.testimonial-section-wrapper {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
}

.testimonial-section {
  color: white;
  padding: 40px 20px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  margin-top: 77px;
  position: relative;
  z-index: 1;
}

.this-bg {
  position: absolute;
  top: -350px;
  left: -25%;
  width: 150%;
  height: auto;
  max-width: none;
  z-index: -1;
  pointer-events: none;
}

h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 30px;
  font-weight: normal;
}

.testimonial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-bottom: 30px;
  position: relative;
  overflow: visible;
  padding: 0 60px;
  margin-top: 55px;
}

.testimonial-card {
  background-color: rgba(255, 255, 255, 0.067);
  border-radius: 50px;
  padding: 20px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1.5px solid transparent;
  background-clip: padding-box;
}

.testimonial-card.active {
  opacity: 1;
  transform: scale(1);
  width: 600px;
  height: 317px;
  padding: 33px;
}

.testimonial-card.side {
  opacity: 0.7;
  transform: scale(0.9);
  width: 525px;
  height: 255px;
}

.quote-background {
  position: absolute;
  top: 7px;
  right: 20px;
  max-width: 240px;
  z-index: 0;
  opacity: 0.7;
}

.testimonial-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}

.author-image {
  width: 92px;
  height: 92px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

.author-info {
  text-align: left;
}

.author-info h4 {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.26px;
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.author-info p {
  margin: 5px 0 0;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  background: rgba(136, 147, 187, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.testimonial-content {
  font-family: 'Libre Baskerville', 'Times New Roman', serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  background: rgba(146, 157, 197, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.testimonial-card.side .testimonial-content {
  font-size: 14px;
}

.nav-button {
  background: rgba(24, 29, 48, 1);
  color: white;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-button:hover {
  background: linear-gradient(135deg, #7B68EE, #6A5ACD);
}

.nav-button.prev {
  left: 10px;
}

.nav-button.next {
  right: 10px;
}

.get-started-button-container {
  display: flex;
  justify-content: center;
  margin-top: 70px;
}

@media (max-width: 1200px) {
  .testimonial-card.active {
    width: 500px;
    height: auto;
    min-height: 317px;
  }

  .testimonial-card.side {
    width: 425px;
    height: auto;
    min-height: 255px;
  }

  .testimonial-container {
    gap: 30px;
  }
}

@media (max-width: 1024px) {
  .testimonial-card.active {
    width: 450px;
  }

  .testimonial-card.side {
    width: 375px;
  }

  .testimonial-container {
    gap: 20px;
    padding: 0 40px;
  }

  .author-image {
    width: 70px;
    height: 70px;
  }

  .author-info h4 {
    font-size: 20px;
  }

  .author-info p {
    font-size: 12px;
  }

  .testimonial-content {
    font-size: 14px;
    line-height: 24px;
  }
}
@media (min-width: 1200px) and (max-width: 1700px) {
  .testimonial-card.active {
    height: auto;
    min-height: 317px;
    max-height: none;
  }

  .testimonial-content {
    max-height: none;
    overflow: visible;

  }
  .testimonial-card {
    min-height: 300px;
  }
}

@media (max-width: 900px) {
  .testimonial-container {
    flex-direction: column;
    gap: 30px;
    padding: 0 20px;
  }

  .testimonial-card.active,
  .testimonial-card.side {
    width: 100%;
    max-width: 500px;
    height: auto;
    min-height: unset;
  }

  .testimonial-card.side {
    display: none;
  }

  .nav-button {
    top: auto;
    bottom: -85px;
  }

  .nav-button.prev {
    left: calc(50% - 60px);
  }

  .nav-button.next {
    right: calc(50% - 60px);
  }

  .get-started-button-container {
    margin-top: 90px;
  }
}

@media (max-width: 600px) {
  h2 {
    font-size: 2em;
  }

  .author-image {
    width: 60px;
    height: 60px;
  }

  .author-info h4 {
    font-size: 18px;
  }

  .author-info p {
    font-size: 11px;
  }

  .testimonial-content {
    font-size: 13px;
    line-height: 22px;
  }
}