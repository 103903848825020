/* Base styles */
.seo-landing-page {
    background-color: #0A0A0A;
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;
  }
  
  .seo-section {
    position: relative;
    width: 100%;
  }
  
  /* Section that needs max-width container */
  .seo-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
  }
  
  /* Scrollbar Styles */
  .seo-landing-page::-webkit-scrollbar {
    width: 8px;
  }
  
  .seo-landing-page::-webkit-scrollbar-track {
    background: #0A0A0A;
  }
  
  .seo-landing-page::-webkit-scrollbar-thumb {
    background: #E89B5D;
    border-radius: 4px;
  }
  
  .seo-landing-page::-webkit-scrollbar-thumb:hover {
    background: #D17E3B;
  }
  
  /* Common Typography */
  .seo-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: clamp(36px, 5vw, 56px);
    font-weight: 600;
    color: #FFFFFF;
    letter-spacing: -0.03em;
    margin-bottom: 24px;
  }
  
  .seo-subtitle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: clamp(16px, 2vw, 18px);
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.6;
    margin-bottom: 48px;
  }
  
  .seo-text {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
  }
  
  /* Common Gradients */
  .seo-gradient-primary {
    background: linear-gradient(92.81deg, #E89B5D 6.58%, #D17E3B 100%);
  }
  
  .seo-gradient-bg {
    background: linear-gradient(92.81deg, rgba(232, 155, 93, 0.1) 6.58%, rgba(209, 126, 59, 0.1) 100%);
  }
  
  .seo-gradient-radial {
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.1) 0%, transparent 70%);
  }
  
  /* Common Button Styles */
  .seo-button {
    background: linear-gradient(92.81deg, #E89B5D 6.58%, #D17E3B 100%);
    color: white;
    border: none;
    padding: 16px 40px;
    border-radius: 30px;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .seo-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(232, 155, 93, 0.4);
  }
  
  /* Common Card Styles */
  .seo-card {
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    padding: 40px;
    transition: all 0.3s ease;
  }
  
  .seo-card:hover {
    transform: translateY(-5px);
    border-color: rgba(232, 155, 93, 0.3);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .seo-container {
      padding: 0 40px;
    }
  }
  
  @media (max-width: 768px) {
    .seo-container {
      padding: 0 20px;
    }
  
    .seo-card {
      padding: 24px;
    }
  }
  
  /* Animation Keyframes */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Utility Classes */
  .seo-fade-in {
    animation: fadeIn 0.6s ease-out forwards;
  }
  
  .seo-fade-up {
    animation: fadeInUp 0.6s ease-out forwards;
  }
  
  .seo-delay-1 { animation-delay: 0.2s; }
  .seo-delay-2 { animation-delay: 0.4s; }
  .seo-delay-3 { animation-delay: 0.6s; }
  .seo-delay-4 { animation-delay: 0.8s; }