.seo-features {
    padding: 120px 0;
    background-color: #1E1E1E;
    position: relative;
    overflow: hidden;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  
  .seo-features-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
    z-index: 2;
  }
  
  .seo-features-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 56px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 80px;
    letter-spacing: -0.03em;
  }
  
  .seo-features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
  }
  
  .seo-feature-card {
    background: linear-gradient(145.76deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    padding: 40px;
    display: flex;
    gap: 30px;
    align-items: flex-start;
    transition: all 0.3s ease;
  }
  
  .seo-feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
    border-color: rgba(232, 155, 93, 0.3);
  }
  
  .seo-feature-icon {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .seo-feature-icon-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
  }
  
  .seo-feature-card:hover .seo-feature-icon-inner {
    transform: scale(1.1);
  }
  
  .seo-feature-content {
    flex-grow: 1;
  }
  
  .seo-feature-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 28px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 20px;
    letter-spacing: -0.02em;
    line-height: 1.3;
  }
  
  .seo-feature-description {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
  }
  
  /* Background Elements */
  .seo-features-bg-grid {
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    height: 100%;
    background-image: 
      linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
    background-size: 40px 40px;
    opacity: 0.15;
    transform: perspective(1000px) rotateX(60deg);
    pointer-events: none;
  }
  
  .seo-features-bg-glow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    height: 120%;
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.1) 0%, transparent 70%);
    pointer-events: none;
    z-index: 1;
  }
  
  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .seo-feature-card {
    animation: fadeInUp 0.6s ease-out forwards;
    opacity: 0;
  }
  
  .seo-feature-card:nth-child(1) { animation-delay: 0.2s; }
  .seo-feature-card:nth-child(2) { animation-delay: 0.3s; }
  .seo-feature-card:nth-child(3) { animation-delay: 0.4s; }
  .seo-feature-card:nth-child(4) { animation-delay: 0.5s; }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .seo-features-grid {
      gap: 40px;
    }
    
    .seo-feature-card {
      padding: 30px;
    }
  }
  
  @media (max-width: 992px) {
    .seo-features-grid {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .seo-features {
      padding: 60px 0;
    }
  
    .seo-features-content {
      padding: 0 20px;
    }
  
    .seo-features-title {
      font-size: 36px;
      margin-bottom: 40px;
      text-align: center;
    }
  
    .seo-features-grid {
      gap: 30px;
    }
  
    .seo-feature-card {
      padding: 24px;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      text-align: center;
    }
  
    .seo-feature-icon {
      width: 60px;
      height: 60px;
    }
  
    .seo-feature-title {
      font-size: 24px;
    }
  }