.healm-process-section {
    padding: 120px 0;
    background: linear-gradient(180deg, #1E1E1E 0%, #141414 100%);
    position: relative;
    overflow: hidden;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  
  .healm-process-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
    z-index: 2;
  }
  
  .healm-process-header {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 56px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 80px;
    letter-spacing: -0.03em;
  }
  
  .healm-process-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .healm-process-item {
    display: flex;
    opacity: 0;
    transform: translateX(-30px);
    animation: processSlideIn 0.6s ease forwards;
  }
  
  .healm-process-item:nth-child(even) {
    justify-content: flex-end;
    transform: translateX(30px);
  }
  
  .healm-process-box {
    flex: 0 1 900px;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    padding: 40px;
    transition: all 0.3s ease;
    margin-left: -40px;
  }
  
  .healm-process-item:nth-child(even) .healm-process-box {
    margin-left: 0;
    margin-right: -40px;
  }
  
  .healm-process-box:hover {
    transform: translateY(-5px);
    border-color: rgba(232, 155, 93, 0.3);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  }
  
  .healm-process-icon-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 16px;
    background: linear-gradient(135deg, 
      rgba(232, 155, 93, 0.15) 0%, 
      rgba(209, 126, 59, 0.15) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E89B5D;
    margin-bottom: 24px;
  }
  
  .healm-process-item-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 28px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 16px;
    letter-spacing: -0.02em;
  }
  
  .healm-process-item-text {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
  }
  
  /* Background Elements */
  .healm-process-gradient-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    background: linear-gradient(180deg, 
      rgba(232, 155, 93, 0.1) 0%,
      transparent 100%
    );
    pointer-events: none;
  }
  
  .healm-process-gradient-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 200px;
    background: linear-gradient(0deg, 
      rgba(232, 155, 93, 0.08) 0%,
      transparent 100%
    );
    pointer-events: none;
  }
  
  .healm-process-grid {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 50px 50px;
    background-image: linear-gradient(90deg, 
      rgba(255, 255, 255, 0.03) 1px, 
      transparent 1px
    );
    pointer-events: none;
    opacity: 0.5;
  }
  
  /* Animations */
  @keyframes processSlideIn {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .healm-process-item:nth-child(1) { animation-delay: 0.2s; }
  .healm-process-item:nth-child(2) { animation-delay: 0.4s; }
  .healm-process-item:nth-child(3) { animation-delay: 0.6s; }
  .healm-process-item:nth-child(4) { animation-delay: 0.8s; }
  
  /* Responsive Design */
  @media (max-width: 992px) {
    .healm-process-box,
    .healm-process-item:nth-child(even) .healm-process-box {
      margin-left: 0;
      margin-right: 0;
    }
  }
  
  @media (max-width: 768px) {
    .healm-process-section {
      padding: 60px 0;
    }
  
    .healm-process-wrapper {
      padding: 0 20px;
    }
  
    .healm-process-header {
      font-size: 36px;
      margin-bottom: 40px;
    }
  
    .healm-process-box {
      padding: 30px;
    }
  
    .healm-process-icon-wrapper {
      width: 50px;
      height: 50px;
    }
  
    .healm-process-item-title {
      font-size: 24px;
    }
  }