.seo-hero {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  
  /* Background Elements */
  .seo-hero-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  
  .seo-hero-bg-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    filter: brightness(1.2);
  }
  
  .seo-hero-bg-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
  
  /* Header */
  .seo-header {
    position: fixed;
    width: 100%;
    z-index: 100;
    padding: 24px 0;
    transition: background-color 0.3s ease;
  }
  
  .seo-header.scrolled {
    background: rgba(0, 0, 0, 0.95);
  }
  
  .seo-header-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .seo-logo img {
    height: 32px;
    width: auto;
  }
  
  .seo-nav {
    display: flex;
    align-items: center;
    gap: 48px;
  }
  
  .seo-nav-links {
    display: flex;
    gap: 40px;
  }
  
  .seo-nav-links a {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .seo-nav-links a:hover {
    color: #E89B5D;
  }
  
  .seo-nav-cta {
    background: #E89B5D;
    color: white;
    border: none;
    padding: 12px 28px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .seo-nav-cta:hover {
    background: #D17E3B;
    transform: translateY(-2px);
  }
  
  /* Main Content */
  .seo-hero-content {
    position: relative;
    z-index: 2;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .hero-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 900px;
  }
  
  .seo-hero-title {
    font-size: 92px;
    line-height: 1;
    color: white;
    margin-bottom: 32px;
    font-weight: 600;
    animation: fadeUp 0.8s ease-out;
  }
  
  .seo-hero-subtitle {
    font-size: 26px;
    line-height: 1.4;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 48px;
    max-width: 700px;
    animation: fadeUp 0.8s ease-out 0.2s both;
  }
  
  .hero-cta {
    animation: fadeUp 0.8s ease-out 0.4s both;
  }
  
  .hero-cta-button {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    background: #E89B5D;
    color: white;
    border: none;
    padding: 18px 36px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .hero-cta-button:hover {
    background: #D17E3B;
    transform: translateY(-2px);
    box-shadow: 0 20px 40px rgba(232, 155, 93, 0.3);
  }
  
  .hero-cta-button svg {
    transition: transform 0.3s ease;
  }
  
  .hero-cta-button:hover svg {
    transform: translateX(4px);
  }
  
  /* Analysis Banner */
  .analysis-banner {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(29, 21, 15, 0.95);
    padding: 24px 0;
    transform: translateY(100%);
    animation: slideUp 0.8s cubic-bezier(0.16, 1, 0.3, 1) 0.8s forwards;
    border-radius: 16px 16px 0 0;
  }
  
  .analysis-content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .analysis-left {
    display: flex;
    align-items: center;
    gap: 40px;
  }
  
  .analysis-info {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  /* Beacon/Pulse */
  .beacon-container {
    position: relative;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 22px;
    margin-left: -3px;
  }
  
  .beacon {
    width: 12px;
    height: 12px;
    background: #E89B5D;
    border-radius: 50%;
    position: relative;
  }
  
  .beacon::before,
  .beacon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: #E89B5D;
    border-radius: 50%;
    animation: beacon 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  
  .beacon::after {
    animation-delay: -1s;
  }
  
  @keyframes beacon {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.8;
    }
    100% {
      transform: translate(-50%, -50%) scale(4);
      opacity: 0;
    }
  }
  
  .analysis-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .analysis-label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .analysis-title {
    font-size: 18px;
    color: white;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .analysis-highlight {
    color: #E89B5D;
  }
  
  .analysis-cta {
    color: white;
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s ease;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin-left: 133px;
  }
  
  .analysis-cta:hover {
    color: #E89B5D;
  }
  
  .analysis-cta svg {
    transition: transform 0.2s ease;
  }
  
  .analysis-cta:hover svg {
    transform: translateX(4px);
  }
  
  .analysis-stats {
    display: flex;
    align-items: center;
    gap: 40px;
    padding-left: 40px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .stat-item {
    text-align: center;
  }
  
  .stat-value {
    font-size: 24px;
    color: #E89B5D;
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  .stat-label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
  }
  
  /* Animations */
  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  /* Media Queries */
  @media (max-width: 1440px) {
    .seo-header-container,
    .seo-hero-content,
    .analysis-content {
      padding: 0 60px;
    }
    
    .seo-hero-title {
      font-size: 82px;
    }
  }
  
  @media (max-width: 1200px) {
    .seo-hero-title {
      font-size: 72px;
    }
  }
  
  @media (max-width: 768px) {
    .seo-header-container,
    .seo-hero-content,
    .analysis-content {
      padding: 0 24px;
    }
  
    .seo-nav {
      display: none;
    }
  
    .seo-hero-title {
      font-size: 48px;
    }
  
    .seo-hero-subtitle {
      font-size: 20px;
    }
  
    .analysis-content {
      flex-direction: column;
      gap: 24px;
    }
  
    .analysis-left {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  
    .analysis-stats {
        padding-left: 0;
        border-left: none;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
      }
    
      .stat-item {
        flex: 1;
        min-width: 150px;
      }
      
      .hero-cta-button {
        width: 100%;
        justify-content: center;
      }
    }
    
    @media (max-width: 480px) {
      .seo-hero-content {
        padding: 0 16px;
      }
    
      .analysis-content {
        padding: 0 16px;
      }
    
      .seo-hero-title {
        font-size: 36px;
      }
    
      .seo-hero-subtitle {
        font-size: 18px;
      }
    
      .stat-item {
        min-width: calc(50% - 20px);
      }
    
      .analysis-title {
        font-size: 16px;
      }
    
      .analysis-label {
        font-size: 12px;
      }
    
      .stat-value {
        font-size: 20px;
      }
    
      .stat-label {
        font-size: 12px;
      }
    }
    /* Analysis Banner */
.analysis-banner {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, 
      rgba(232, 155, 93, 0.1), 
      rgba(232, 155, 93, 0.15) 50%,
      rgba(232, 155, 93, 0.1)
    );
    border-top: 1px solid rgba(232, 155, 93, 0.2);
    padding: 24px 0;
    transform: translateY(100%);
    animation: slideUp 0.8s cubic-bezier(0.16, 1, 0.3, 1) 0.8s forwards;
    border-radius: 16px 16px 0 0;
    backdrop-filter: blur(10px);
  }
  
  .analysis-content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .analysis-left {
    display: flex;
    align-items: center;
    gap: 40px;
  }
  
  .analysis-info {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .analysis-stats {
    display: flex;
    align-items: center;
    gap: 40px;
    padding-left: 40px;
    border-left: 1px solid rgba(232, 155, 93, 0.2);
  }

  .analysis-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .analysis-label {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 500;
  }
  
  .analysis-title {
    font-size: 22px;
    color: white;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 6px;
    letter-spacing: -0.01em;
  }
  
  .analysis-highlight {
    color: #E89B5D;
    font-weight: 600;
    display: inline;
  }
  
  /* Updated hover effect for the link text */
  .analysis-cta {
    color: white;
    text-decoration: none;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s ease;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    font-weight: 500;
    opacity: 0.9;
  }
  
  .analysis-cta:hover {
    color: #E89B5D;
    opacity: 1;
  }

  .analysis-cta {
    color: white;
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
    background: transparent;
    border: 1px solid rgba(232, 155, 93, 0.3);
    padding: 10px 20px;
    margin-left: 103px;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
  }
  
  .analysis-cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, 
      rgba(232, 155, 93, 0.1), 
      rgba(232, 155, 93, 0.05)
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .analysis-cta:hover {
    color: #E89B5D;
    border-color: rgba(232, 155, 93, 0.6);
    transform: translateY(-1px);
  }
  
  .analysis-cta:hover::before {
    opacity: 1;
  }
  
  .analysis-cta svg {
    transition: transform 0.3s ease;
    position: relative;
    z-index: 1;
  }
  
  .analysis-cta:hover svg {
    transform: translateX(4px);
  }
  
  .analysis-cta span {
    position: relative;
    z-index: 1;
  }

  /* Base styles remain the same until media queries */

@media (max-width: 1024px) {
  .seo-hero-title {
    font-size: 64px;
    margin-bottom: 24px;
  }

  .seo-hero-subtitle {
    font-size: 22px;
    margin-bottom: 36px;
  }

  .analysis-stats {
    gap: 24px;
  }
}

@media (max-width: 768px) {
  .seo-header-container {
    padding: 0 20px;
  }

  .seo-hero-content {
    padding: 0 20px;
  }

  .seo-hero-title {
    font-size: 48px;
    margin-bottom: 20px;
  }

  .seo-hero-subtitle {
    font-size: 20px;
    margin-bottom: 32px;
  }

  .hero-main {
    margin-top: 80px;
  }

  /* Analysis Banner Mobile Styles */
  .analysis-banner {
    padding: 20px 0;
  }

  .analysis-content {
    padding: 0 20px;
    flex-direction: column;
    gap: 24px;
  }

  .analysis-left {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .analysis-cta {
    margin-left: 0;
    width: 100%;
    justify-content: center;
  }

  .analysis-stats {
    padding-left: 0;
    border-left: none;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .stat-item {
    flex: 0 0 calc(50% - 8px);
    text-align: left;
  }
}

@media (max-width: 480px) {
  .seo-hero-content {
    padding: 0 16px;
  }

  .seo-hero-title {
    font-size: 36px;
    margin-bottom: 16px;
  }

  .seo-hero-subtitle {
    font-size: 18px;
    margin-bottom: 28px;
    line-height: 1.5;
  }

  .hero-cta-button {
    width: 100%;
    justify-content: center;
    padding: 16px 24px;
    font-size: 16px;
  }

  .analysis-banner {
    padding: 16px 0;
  }

  .analysis-content {
    padding: 0 16px;
  }

  .analysis-title {
    font-size: 18px;
  }

  .analysis-label {
    font-size: 11px;
  }

  .stat-item {
    min-width: calc(50% - 8px);
  }

  .stat-value {
    font-size: 20px;
  }

  .stat-label {
    font-size: 12px;
  }

  /* Mobile Navigation Adjustments */
  .seo-nav {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 80%;
    max-width: 300px;
    background: rgba(0, 0, 0, 0.95);
    padding: 80px 24px;
    transition: right 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .seo-nav.active {
    right: 0;
  }

  .seo-nav-links {
    flex-direction: column;
    gap: 24px;
  }

  .seo-nav-links a {
    font-size: 18px;
    padding: 8px 0;
  }

  .seo-nav-cta {
    width: 100%;
    margin-top: 16px;
  }
}

/* Additional Mobile Optimizations */
@media (max-width: 360px) {
  .seo-hero-title {
    font-size: 32px;
  }

  .seo-hero-subtitle {
    font-size: 16px;
  }

  .analysis-stats {
    gap: 12px;
  }

  .stat-value {
    font-size: 18px;
  }

  .stat-label {
    font-size: 11px;
  }
}

/* Mobile Menu Button */
.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 8px;
  z-index: 101;
}

.mobile-menu-button:hover {
  color: #E89B5D;
}

/* Base styles remain the same until media queries */

@media (max-width: 1024px) {
  .seo-hero-title {
    font-size: 64px;
    margin-bottom: 24px;
  }

  .seo-hero-subtitle {
    font-size: 22px;
    margin-bottom: 36px;
  }

  .analysis-stats {
    gap: 24px;
  }
}

@media (max-width: 768px) {
  .seo-header-container {
    padding: 0 20px;
  }

  .seo-hero-content {
    padding: 0 20px;
  }

  .seo-hero-title {
    font-size: 48px;
    margin-bottom: 20px;
  }

  .seo-hero-subtitle {
    font-size: 20px;
    margin-bottom: 32px;
  }

  .hero-main {
    margin-top: 80px;
  }

  /* Analysis Banner Mobile Styles */
  .analysis-banner {
    padding: 20px 0;
  }

  .analysis-content {
    padding: 0 20px;
    flex-direction: column;
    gap: 24px;
  }

  .analysis-left {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .analysis-cta {
    margin-left: 0;
    width: 100%;
    justify-content: center;
  }

  .analysis-stats {
    padding-left: 0;
    border-left: none;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .stat-item {
    flex: 0 0 calc(50% - 8px);
    text-align: left;
  }
}

@media (max-width: 480px) {
  .seo-hero-content {
    padding: 0 16px;
  }

  .seo-hero-title {
    font-size: 36px;
    margin-bottom: 16px;
  }

  .seo-hero-subtitle {
    font-size: 18px;
    margin-bottom: 28px;
    line-height: 1.5;
  }

  .hero-cta-button {
    width: 100%;
    justify-content: center;
    padding: 16px 24px;
    font-size: 16px;
  }

  .analysis-banner {
    padding: 16px 0;
  }

  .analysis-content {
    padding: 0 16px;
  }

  .analysis-title {
    font-size: 18px;
  }

  .analysis-label {
    font-size: 11px;
  }

  .stat-item {
    min-width: calc(50% - 8px);
  }

  .stat-value {
    font-size: 20px;
  }

  .stat-label {
    font-size: 12px;
  }

  /* Mobile Navigation Adjustments */
  .seo-nav {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 80%;
    max-width: 300px;
    background: rgba(0, 0, 0, 0.95);
    padding: 80px 24px;
    transition: right 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .seo-nav.active {
    right: 0;
  }

  .seo-nav-links {
    flex-direction: column;
    gap: 24px;
  }

  .seo-nav-links a {
    font-size: 18px;
    padding: 8px 0;
  }

  .seo-nav-cta {
    width: 100%;
    margin-top: 16px;
  }
}

/* Additional Mobile Optimizations */
@media (max-width: 360px) {
  .seo-hero-title {
    font-size: 32px;
  }

  .seo-hero-subtitle {
    font-size: 16px;
  }

  .analysis-stats {
    gap: 12px;
  }

  .stat-value {
    font-size: 18px;
  }

  .stat-label {
    font-size: 11px;
  }
}

/* Add these new styles to your existing CSS */

.seo-nav-cta {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #E89B5D;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.seo-nav-cta:hover {
  background: #D17E3B;
  transform: translateY(-2px);
}

.phone-icon {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.analysis-cta {
  display: flex;
  align-items: center;
  gap: 12px;
  color: white;
  font-size: 16px;
  transition: all 0.2s ease;
  background: none;
  border: none;
  cursor: pointer;
}

.analysis-cta .phone-icon {
  color: #E89B5D;
}

.analysis-cta:hover {
  color: #E89B5D;
}

.analysis-cta:hover .phone-icon {
  transform: scale(1.1);
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .seo-nav-cta {
    padding: 10px 20px;
    font-size: 14px;
  }

  .analysis-cta {
    font-size: 14px;
  }
}