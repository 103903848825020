.get-started-button {
  background: linear-gradient(92.81deg, #7C42F6 6.58%, #42AAF6 100%);
  color: white;
  width: 341px;
  height: 72px;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  position: relative;
  overflow: visible;
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: none;
}

.get-started-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(124, 66, 246, 0.4);
}

.get-started-button::after {
  content: '';
  position: absolute;
  top: -7px;
  left: -7px;
  right: -7px;
  bottom: -7px;
  border: 7px solid rgba(255, 255, 255, 0.134);
  border-radius: 43px;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.get-started-button:hover::after {
  opacity: 0.8;
}

.button-icon-1 {
  font-size: 24px;
}

/* Media Queries */
@media (max-width: 768px) {
  .get-started-button {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 576px) {
  .get-started-button {
    height: 60px;
    font-size: 0.9rem;
  }
  
  .button-icon {
    font-size: 20px;
  }
}