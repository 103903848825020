/* PartnersSection.css */
@font-face {
    font-family: 'Apex Mk3';
    src: url('../fonts/Apex\ Mk3-ExtraLight.otf') format('opentype');
    font-weight: 200; /* or whatever the correct weight is for ExtraLight */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Apex Mk3';
    src: url('../fonts/Apex\ Mk3-Medium.otf') format('opentype');
    font-weight: 500; /* this is typically considered 'medium' weight */
    font-style: normal;
  }

  .partners-section-tour {
    color: #FFF;
    /* text-align: center; */
    width: 100vw;

    /* margin-top: 77px; */
    z-index: 0;

}

.partners-section-title{
    font-family: Apex Mk3;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 70px; /* Add space below the title */
    z-index: 10;
}

.partners-list {
    display: flex;
    justify-content: center; /* Center items horizontally */
    gap: 70px; 
    margin-top: 70px;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    white-space: nowrap; /* Keep items in one line */
  

    margin-left: -140px;
    margin-right: -140px;

}

  
.partner {
    flex: 0 0 auto; 
    display: flex; /* Enables flexbox */
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    /* animation: scrollLogos 10s linear infinite; */

}

.partner img {
    max-width: 100%; /* Ensure image is responsive within container */
    width: 100px;
    height: auto; /* Maintain aspect ratio */
}

.partners-disclaimer{
    margin-top: 52px;
    font-family: Apex Mk3;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
 
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .partner {
        max-width: 20%; /* Adjust the max-width for a better fit */
    }
    .partners-list {
        gap: 50px; /* Adjust the gap to match the desktop version */
    }
}



