.landing-page {
  width: 100%;
  background-color: rgb(4, 9, 28);
}

.content-wrapper {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* You can add more global styles for the landing page here */