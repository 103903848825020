@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.hero {
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  position: relative;
  min-height: 600px; /* Add this line */

}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin-top: 33px;
}

.hero-content {
  max-width: 1200px;
  width: 100%;
  margin: auto auto 100px;
  padding: 0 20px;
}

.hero-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 80px;
  font-weight: 700;
  line-height: 96px;
  letter-spacing: -0.02em;
  text-align: left;
  color: white;
  margin-bottom: 40px;
  max-width: 65%;
}

.highlight {
  color: #b388ff;
}

.cta-button {
  background: linear-gradient(92.81deg, #7C42F6 6.58%, #42AAF6 100%);
  color: white;
  width: 341px;
  height: 72px;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  position: relative;
  overflow: visible;
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(124, 66, 246, 0.4);
}

.cta-button::after {
  content: '';
  position: absolute;
  top: -7px;
  left: -7px;
  right: -7px;
  bottom: -7px;
  border: 7px solid rgba(255, 255, 255, 0.134);
  border-radius: 43px;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.cta-button:hover::after {
  opacity: 0.8;
}

.hero-subtitle {
  color: #cccccc;
  font-family: Inter, sans-serif;
  margin-top: 2.5rem;
  font-size: 0.9rem;
  opacity: 0.9;
}

/* Media Queries for Responsiveness */

@media (max-width: 1200px) {
  .hero-title {
    font-size: 64px;
    line-height: 76px;
    max-width: 80%;
  }
}

@media (max-width: 992px) {
  .hero-title {
    font-size: 48px;
    line-height: 58px;
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 500px;
  
    min-height: 70vh;
  }

  .hero-overlay {
    position: relative;
    margin-top: 0;
    padding-top: 80px;
    padding-bottom: 40px;
    justify-content: center;
  }

  .hero-content {
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hero-title {
    font-size: 36px;
    line-height: 44px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 30px;
    padding-top: 44px;
  }

  .cta-button {
    width: 100%;
    max-width: 280px;
    height: 60px;
    font-size: 20px;
  }

  .hero-subtitle {
    font-size: 0.8rem;
    margin-top: 1.5rem;
  }
}

@media (max-width: 576px) {
  .hero-title {
    font-size: 28px;
    line-height: 34px;
  }
  
  .cta-button {
    height: 50px;
    font-size: 18px;
  }
}

/* New media query for shorter heights */
@media (max-height: 800px) {
  .hero {
    height: auto;
    min-height: 100vh;
  }

  .hero-overlay {
    /* position: relative; */
    padding-top: 90px;
    padding-bottom: 30px;
  }

  .hero-title {
    font-size: 3.5vw;
    /* margin-bottom: 3vh; */
  }

  .cta-button {
    height: 50px;
    font-size: 18px;
  }

  .hero-subtitle {
    margin-top: 1rem;
  }
}

/* Additional media query for very short heights */
@media (max-height: 600px) {
  .hero-title {
    font-size: 3.5vw;
    margin-bottom: 2vh;
  }

  .cta-button {
    height: 40px;
    font-size: 16px;
  }

  .hero-subtitle {
    font-size: 0.7rem;
  }
}