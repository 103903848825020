.green-btn-login{
    background: linear-gradient(180deg, #16d00f -46.51%, #08862e 138.37%);
    transition: box-shadow 0.5s;
    font-size: 16px;
    color: white;
    border-radius: 10px;
    padding-left: 25px;
    margin-left: 30px;
    width: 100%;
    display: flex;
    box-shadow: 0 0 0 green;
    height: 48px;


}
.green-btn-login:hover{
    /* text-decoration: underline; */
    box-shadow: 0px 0px 5px 3px rgba(255, 255, 255, 0.20);}

.green-btn-login svg{
    margin-right: 12px;
    font-size: 26px;
    margin-top: 10px;
}

.green-btn-login p{
    margin: 0;
    padding: 0;
    margin-top: 15px;
    font-weight: 500;
}


.gr-btn-icon{
    font-size: 24px;
    color: white;
    margin-right: 10px
}


@media (max-width: 991px){

    .green-btn-login{
        background: linear-gradient(180deg, #16d00f -46.51%, #08862e 138.37%);
        transition: box-shadow 0.5s;
        font-size: 16px;
        color: white;
        border-radius: 10px;
        width: 100%; 
        /* display: flex; */
        box-shadow: 0 0 0 green;
        margin-bottom: 20px;
        height: 48px;
        text-align: center;
        align-items: center;
        margin-left: 0px;
        padding-left: 30%;
        
    }
    .green-btn-login svg{
        margin-right: 12px;
        font-size: 26px;
        /* margin-top: 10px; */
        margin-top: 0;
   
    }
    
    .green-btn-login p{
        margin: 0;
        padding: 0;
        /* margin-top: 15px; */
        font-weight: 500;
    
    }

    

}



@media (min-width: 1475px){

    .green-btn-login{
   
        height: 61px;
        padding-top: 7px;
       
        
    }

    

    

}

.create-account-btn-v2{
    border-radius: 12px;
    background: var(--PurpleLiner, radial-gradient(100% 100% at 50% 0%, #925FFF 0%, #7C42F6 100%));
    box-shadow: 0px 4px 0px 0px #6034BE;
    display: flex;
    height: 48px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: var(--White, #FFF);

    /* Inter/Semibold/16 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.16px;

    position: absolute; 
    right: 0;
    margin-right: 3rem;
    margin-top: 2.5rem;

}

.create-account-btn-v2:hover{
    opacity: 0.9;

}
