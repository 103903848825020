@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

.header {
  padding: 1rem 0;
  width: 100%;
  top: 0;
  z-index: 1000;
  font-family: 'Inter', sans-serif;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logo img {
  height: 40px;
}

.nav-menu ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-menu li {
  margin-left: 2rem;
}

.nav-menu a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  transition: color 0.3s ease;
}

.nav-menu a:hover {
  color: #b388ff;
}

.social-icons {
  display: flex;
  align-items: center;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  margin-left: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.social-icons a {
  color: #ffffff8a;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: color 0.3s ease;
}

.icon-wrapper:hover {
  background-color: rgba(255, 255, 255, 1);
  transform: scale(1.1);
}

.social-icons a:hover {
  color: #b388ff;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.menu-toggle:hover {
  color: #b388ff;
}

.mobile-social-icons {
  display: none;
}

@media (max-width: 768px) {
  .header-container {
    flex-wrap: wrap;
    position: relative;
  }

  .logo {
    position: relative;
    z-index: 1002; /* Higher than the menu overlay */
  }

  .nav-menu {
    flex-basis: 100%;
    display: none;
  }

  .nav-menu.open {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:  rgba(4, 9, 28, 0.95);
    ;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .nav-menu ul {
    flex-direction: column;
    align-items: center;
  }

  .nav-menu li {
    margin: 0.5rem 0;
  }

  .nav-menu a {
    font-size: 24px;
  }

  .menu-toggle {
    display: block;
    z-index: 1001;
  }

  .desktop-social-icons {
    display: none;
  }

  .mobile-social-icons {
    display: block;
    margin-top: 2rem;
  }

  .mobile-social-icons .social-icons {
    display: flex;
    justify-content: center;
  }

  .mobile-social-icons .icon-wrapper {
    width: 32px;
    height: 32px;
    background-color: rgba(255, 255, 255, 0.15);
    margin: 0 0.5rem;
  }

  .mobile-social-icons .social-icons a {
    color: #ffffff8a;
    font-size: 1rem;
  }
}