.seo-impact {
    padding: 120px 0;
    background: linear-gradient(180deg, #0D1117 0%, #161B22 100%);
    position: relative;
    overflow: hidden;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  
  .seo-impact-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
    z-index: 2;
  }
  
  .seo-impact-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 56px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 24px;
    letter-spacing: -0.03em;
  }
  
  .seo-impact-subtitle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    max-width: 800px;
    margin: 0 auto 80px;
  }
  
  .seo-impact-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
    margin-bottom: 60px;
  }
  
  .seo-impact-metric {
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 24px;
    padding: 40px;
    position: relative;
    overflow: hidden;
  }
  
  .seo-impact-metric::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, 
      transparent,
      rgba(232, 155, 93, 0.3),
      transparent
    );
  }
  
  .metric-header {
    margin-bottom: 30px;
  }
  
  .metric-value {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 48px;
    font-weight: 700;
    color: #E89B5D;
    display: block;
    margin-bottom: 16px;
  }
  
  .metric-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    letter-spacing: -0.02em;
  }
  
  .dot-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;
  }
  
  .impact-dot {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
  }
  
  .impact-dot.active {
    background: #E89B5D;
    box-shadow: 0 0 20px rgba(232, 155, 93, 0.3);
  }
  
  .seo-impact-footer {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    letter-spacing: -0.01em;
  }
  
  /* Visual Elements */
  .impact-sphere {
    position: absolute;
    width: 600px;
    height: 600px;
    right: -200px;
    top: -200px;
    background: radial-gradient(circle at center, 
      rgba(232, 155, 93, 0.1) 0%,
      rgba(232, 155, 93, 0.05) 30%,
      transparent 70%
    );
    border-radius: 50%;
    filter: blur(60px);
  }
  
  .impact-glow-1 {
    position: absolute;
    width: 400px;
    height: 400px;
    left: -100px;
    bottom: -100px;
    background: radial-gradient(circle at center,
      rgba(232, 155, 93, 0.08) 0%,
      transparent 70%
    );
    filter: blur(40px);
  }
  
  .impact-glow-2 {
    position: absolute;
    width: 300px;
    height: 300px;
    left: 40%;
    top: 20%;
    background: radial-gradient(circle at center,
      rgba(232, 155, 93, 0.05) 0%,
      transparent 70%
    );
    filter: blur(50px);
  }
  
  .impact-lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 2px,
      rgba(232, 155, 93, 0.03) 2px,
      rgba(232, 155, 93, 0.03) 4px
    );
    pointer-events: none;
  }
  
  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .seo-impact-metric {
    animation: fadeInUp 0.6s ease-out forwards;
    opacity: 0;
  }
  
  .seo-impact-metric:nth-child(1) { animation-delay: 0.2s; }
  .seo-impact-metric:nth-child(2) { animation-delay: 0.3s; }
  .seo-impact-metric:nth-child(3) { animation-delay: 0.4s; }
  .seo-impact-metric:nth-child(4) { animation-delay: 0.5s; }
  
  /* Responsive Design */
  @media (max-width: 992px) {
    .seo-impact-grid {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  }
  
  @media (max-width: 768px) {
    .seo-impact {
      padding: 60px 0;
    }
  
    .seo-impact-content {
      padding: 0 20px;
    }
  
    .seo-impact-title {
      font-size: 36px;
    }
  
    .seo-impact-subtitle {
      font-size: 16px;
      margin-bottom: 40px;
    }
  
    .seo-impact-metric {
      padding: 30px;
    }
  
    .metric-value {
      font-size: 36px;
    }
  
    .metric-title {
      font-size: 20px;
    }
  
    .impact-dot {
      width: 16px;
      height: 16px;
    }
  }

  .impact-dot {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .impact-dot.active {
    background: #E89B5D;
    animation: dotPulse 3s infinite;
  }
  
  .impact-dot.active::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transform: translateX(-100%);
    animation: shimmer 2s infinite;
  }
  
  /* Individual dot delays for wave effect */
  .dot-grid .impact-dot:nth-child(6n+1).active { animation-delay: 0s; }
  .dot-grid .impact-dot:nth-child(6n+2).active { animation-delay: 0.1s; }
  .dot-grid .impact-dot:nth-child(6n+3).active { animation-delay: 0.2s; }
  .dot-grid .impact-dot:nth-child(6n+4).active { animation-delay: 0.3s; }
  .dot-grid .impact-dot:nth-child(6n+5).active { animation-delay: 0.4s; }
  .dot-grid .impact-dot:nth-child(6n+6).active { animation-delay: 0.5s; }
  
  /* Shimmer delays */
  .dot-grid .impact-dot:nth-child(6n+1).active::after { animation-delay: 0s; }
  .dot-grid .impact-dot:nth-child(6n+2).active::after { animation-delay: 0.1s; }
  .dot-grid .impact-dot:nth-child(6n+3).active::after { animation-delay: 0.2s; }
  .dot-grid .impact-dot:nth-child(6n+4).active::after { animation-delay: 0.3s; }
  .dot-grid .impact-dot:nth-child(6n+5).active::after { animation-delay: 0.4s; }
  .dot-grid .impact-dot:nth-child(6n+6).active::after { animation-delay: 0.5s; }
  
  @keyframes dotPulse {
    0% {
      transform: scale(1);
      background: #E89B5D;
    }
    50% {
      transform: scale(0.95);
      background: #D17E3B;
    }
    100% {
      transform: scale(1);
      background: #E89B5D;
    }
  }
  
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }