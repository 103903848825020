.seo-meet {
    padding: 100px 0;
    background: linear-gradient(180deg, #0A0A0A 0%, #151515 100%);
    position: relative;
    overflow: hidden;
  }
  
  .seo-meet-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 60px;
    align-items: center;
  }
  
  .seo-meet-text {
    color: #FFFFFF;
  }
  
  .seo-meet-date {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    color: #E89B5D;
    display: block;
    margin-bottom: 16px;
  }
  
  .seo-meet-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 40px;
    letter-spacing: -0.03em;
  }
  
  .seo-meet-description {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 24px;
  }
  
  .seo-meet-description:last-of-type {
    margin-bottom: 0;
  }
  
  .seo-meet-visual {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .seo-meet-logo {
    width: 100%;
    max-width: 400px;
    aspect-ratio: 1;
    background: radial-gradient(circle at center, rgba(232, 155, 93, 0.1) 0%, transparent 70%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 40px;
  }
  
  .seo-meet-logo img {
    max-width: 200px;
    height: auto;
  }
  
  .seo-meet-cta {
    background: linear-gradient(92.81deg, #E89B5D 6.58%, #D17E3B 100%);
    color: white;
    border: none;
    padding: 16px 40px;
    border-radius: 30px;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.2px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: block;
    margin: 60px auto 0;
  }
  
  .seo-meet-cta:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(232, 155, 93, 0.4);
  }
  
  /* Responsive Design */
  @media (max-width: 992px) {
    .seo-meet-content {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  
    .seo-meet-visual {
      order: -1;
    }
  
    .seo-meet-logo {
      max-width: 300px;
    }
  }
  
  @media (max-width: 768px) {
    .seo-meet {
      padding: 60px 0;
    }
  
    .seo-meet-title {
      font-size: 36px;
      margin-bottom: 30px;
    }
  
    .seo-meet-description {
      font-size: 16px;
    }
  
    .seo-meet-logo {
      max-width: 150px;
    }
  }
  
  /* Animation for logo glow */
  @keyframes glowPulse {
    0% { box-shadow: 0 0 20px rgba(232, 155, 93, 0.2); }
    50% { box-shadow: 0 0 40px rgba(232, 155, 93, 0.3); }
    100% { box-shadow: 0 0 20px rgba(232, 155, 93, 0.2); }
  }
  
  .seo-meet-logo {
    animation: glowPulse 3s infinite ease-in-out;
  }