@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Inter:wght@500&display=swap');

.benefits-section-wrapper {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
  height: 530px;
}

.benefits-section {
  display: flex;
  justify-content: space-between;
  padding: 4rem 2rem;
  color: white;
  position: relative;
  z-index: 1;
}

.ring-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  max-width: none;
  z-index: 0;
  pointer-events: none;
}

.benefit-item {
  flex: 1;
  max-width: 45%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-top: 159px;
}

.benefit-item h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1rem;
}

.benefit-item p {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  opacity: 0.8;
  color: rgba(136, 147, 187, 1);
}

/* ... (keep all the non-responsive CSS the same) ... */

/* Responsive adjustments */
/* Responsive adjustments */
@media (max-width: 768px) {
  .benefits-section-wrapper {
    height: auto;  /* Allow the wrapper to expand */
    min-height: 530px;  /* Maintain minimum height */
    padding-top: 70px;
  }

  .benefits-section {
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
  }

  .benefit-item {
    max-width: 100%;
    margin-top: 2rem;  /* Adjust top margin */
    margin-bottom: 2rem;  /* Add space between items when stacked */
  }

  .benefit-item:first-child {
    margin-top: 0;  /* Remove top margin for the first item */
  }

  .benefit-item:last-child {
    margin-bottom: 0;  /* Remove bottom margin for the last item */
  }

  .ring-bg {
    height: 100%;
    object-fit: cover;
  }
}