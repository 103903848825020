.healm-ft-container {
    color: #ffffff;
    padding: 40px 0px;
    font-family: 'Inter', sans-serif;
    margin-top: 100px;
}

.healm-ft-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.healm-ft-brand-section {
    flex-basis: 40%;
}

.healm-ft-brand-section img {
    height: 50px;
    margin-bottom: 20px;
}

.healm-ft-brand-description {
    font-size: 14px;
    line-height: 1.5;
    color: #8893BB;
    margin-top: 20px;
}

.healm-ft-nav-links {
    display: flex;
    gap: 60px;
}

.healm-ft-nav-column ul {
    list-style-type: none;
    padding: 0;
}

.healm-ft-nav-column li {
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
    color: #8893BB;
}

.healm-ft-social-links {
    display: flex;
    gap: 15px;
}

.healm-ft-social-icon {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-decoration: none;
}

.healm-ft-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 14px;
}

.healm-ft-policies a {
    color: #8893BB;
    text-decoration: none;
}

.healm-ft-copyright {
    color: #8893BB;
}
.footer-nav-link {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
  
  .footer-nav-link:hover {
    text-decoration: none;
    color: inherit;
  }
  
  .healm-ft-nav-column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .healm-ft-nav-column li {
    margin-bottom: 10px;
  }

@media (max-width: 768px) {
    .healm-ft-container {
        padding: 20px;
    }

    .healm-ft-content {
        flex-direction: column;
    }

    .healm-ft-brand-section, .healm-ft-nav-links, .healm-ft-social-links {
        margin-bottom: 30px;
    }

    .healm-ft-nav-links {
        flex-direction: column;
        gap: 20px;
    }

    .healm-ft-bottom {
        flex-direction: column;
        align-items: flex-start;
    }

    .healm-ft-policies {
        margin-bottom: 10px;
    }
}
